:root {
  --screen-w-sm: 640px;
  --screen-w-md: 768px;
  --screen-w-lg: 1024px;
  --screen-w-xl: 1280px;
  --screen-w-2xl: 1536px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

#__next {
  @apply h-full;
}

.editor a {
  @apply text-blue-500;
  text-decoration: underline;
  font-weight: 600;
}

.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-2xl {
  width: 100%;
}

@media (min-width: 640px) {
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-2xl {
    max-width: var(--screen-w-sm);
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-lg,
  .container-xl,
  .container-2xl {
    max-width: var(--screen-w-md);
  }
}

@media (min-width: 1024px) {
  .container-lg,
  .container-xl,
  .container-2xl {
    max-width: var(--screen-w-lg);
  }
}

@media (min-width: 1280px) {
  .container-xl,
  .container-2xl {
    max-width: var(--screen-w-xl);
  }
}

@media (min-width: 1536px) {
  .container-2xl {
    max-width: var(--screen-w-2xl);
  }
}
